* {
  margin: 0;
  padding: 0;
  transition: 3s;
  font-family: "DM Sans", sans-serif;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes animateDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(3px);
  }
}

.App {
  background: -moz-radial-gradient(
    circle,
    rgb(184, 182, 181) 0%,
    rgb(64, 64, 59) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgb(184, 182, 181) 0%,
    rgb(64, 64, 59) 100%
  );
  background: radial-gradient(
    circle,
    rgb(184, 182, 181) 0%,
    rgb(64, 64, 59) 100%
  );
}

.carousel-control-next,
.carousel-control-prev {
  font-size: 40px;
  color: rgb(56, 50, 50) !important;
}

.carousel-control-prev > svg {
  border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 8px 0px 22px -2px #030012;
  box-shadow: 8px 0px 22px -2px #030012;
  padding: 5px;
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  text-decoration: none;
}

.carousel-control-next > svg {
  border-radius: 50%;
  -webkit-box-shadow: -8px 0px 22px -2px #030012;
  box-shadow: -8px 0px 22px -2px #030012;
  padding: 5px;
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  text-decoration: none;
}
/* 
.carousel img {
  width: 75%;
  
  margin: auto;
} */

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 450px) {
.carousel-control-prev>svg {
    font-size: 30px;
  }
}

@media only screen and (max-width: 450px) {
  .carousel-control-next>svg {
    font-size: 30px;
  }
}